@tailwind base;
@tailwind components;
@tailwind utilities;

/* ==[ FONTS ]== */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

/* ==[ MAIN ]== */

body {
  font-family: "Inter", sans-serif;
  @apply bg-light;
}

::-webkit-scrollbar {
  display: none;
}

button {
  @apply rounded-full px-8 py-3 md:px-16 md:py-4 mx-auto md:mx-0 bg-main w-fit;
  color: white;
}

button h3 {
  @apply font-bold text-xl md:text-2xl;
  color: white;
}

button p {
  @apply font-light text-xs md:text-sm;
  color: white;
}

nav {
  @apply shadow-lg rounded-full px-10 py-8 sticky top-10 backdrop-filter backdrop-blur-lg w-[420px] mx-auto z-50;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

nav > ul {
  @apply flex items-center justify-between;
}

/* ==[ TYPOGRAPHY ]== */

h1 {
  @apply text-4xl text-center md:text-left md:text-7xl font-extrabold ;
}

h2 {
  @apply text-4xl text-center md:text-left md:text-6xl font-bold;
}

h3 {
  @apply md:text-4xl text-center md:text-left font-bold;
}

p {
  @apply text-center md:text-left md:text-[21px] font-normal;
}

section {
  backgroundposition: "center";
  backgroundsize: "cover";
  backgroundrepeat: "no-repeat";
}

/* ==[ PRESETS ]== */

.logo {
  @apply font-bold text-xl; 
}

.white {
  color: white;
}

.card {
  @apply rounded-3xl shadow p-5 md:px-10 relative;
  background-color: white;
}

.card > p {
  @apply opacity-60 text-left;
}
